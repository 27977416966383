import React from 'react';
import {QuizData} from '../../services/fortune';
import {useTranslation} from 'react-i18next';

interface Props {
  onQuestionSubmit: (question: string) => void;
  quizData: QuizData;
}

const QuestionStep = ({onQuestionSubmit}: Props) => {
  const {t} = useTranslation();

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    const question = e.target.question.value;

    onQuestionSubmit(question);
  };

  return (
    <form className='flex flex-wrap flex-col items-center max-w-sm mx-auto' onSubmit={onFormSubmit}>
      <input
        required
        name='question'
        placeholder={t('quiz.question.input')}
        className='input text-neutral bg-white w-full focus:outline-none focus:border-2 focus:border-secondary mb-6'
      />
      <button className='btn btn-secondary w-44 text-white' type='submit'>
        {t('quiz.continue')}
      </button>
    </form>
  );
};

export default QuestionStep;
