import React from 'react';

interface Props {
  cardSlug?: string;
  pulse: boolean;
  small?: boolean;
}

const SelectedCard = ({cardSlug, pulse, small}: Props) => {
  return (
    <div
      className={`${
        small ? 'lg:w-[96px] lg:h-[154px] w-[56px] h-[90px]' : 'lg:w-[140px] lg:h-[224px] w-[100px] h-[160px]'
      } card  bg-black${pulse ? ' animate-scale-pulse ' : ' '}${
        cardSlug ? 'border-2 border-black' : 'border border-dashed border-warning'
      }`}
    >
      {cardSlug === undefined ? (
        <div className='card-body flex justify-center items-center text-center py-2' />
      ) : (
        <div className='h-[calc(100%+18px)] w-[calc(100%+18px)] -translate-y-[9px] -translate-x-[9px]'>
          <img className='h-full' width={323} height={550} src={`/images/tarot/${cardSlug}.png`} alt={cardSlug} />
        </div>
      )}
    </div>
  );
};

export default SelectedCard;
