import React, {PropsWithChildren, useState} from 'react';
import {Link} from 'gatsby-plugin-react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import {useAuthentication} from '../context/authentication';
import {useTranslation} from 'react-i18next';
import AuthenticationModal from './AuthenticationModal';
import {StaticImage} from 'gatsby-plugin-image';
import {navigateWithLanguage} from '../services/navigation';
import {useLocation} from '@reach/router';

interface Props extends PropsWithChildren {
  lowContent?: boolean;
  showLanguageSwitcher?: boolean;
  hideAuthentication?: boolean;
  hideFooter?: boolean;
  className?: string;
  logoWithLink?: boolean;
  background?: string;
  logoLink?: string;
}

const PageLayout = ({
  children,
  lowContent = false,
  showLanguageSwitcher = true,
  hideAuthentication = false,
  hideFooter = false,
  className,
  logoWithLink = true,
  background,
  logoLink = '/',
}: Props) => {
  const {user} = useAuthentication();
  const location = useLocation();

  const {
    t,
    i18n: {resolvedLanguage},
  } = useTranslation();

  const params = new URLSearchParams(location.search);
  const login = params.get('login');

  const [showAuthentication, setShowAuthentication] = useState(user === null && login === '1');

  return (
    <>
      {showAuthentication && <AuthenticationModal onClose={() => setShowAuthentication(false)} />}
      <div className={`overflow-x-hidden min-h-screen text-primary ${lowContent ? 'flex flex-col' : ''} ${background ?? ''}`}>
        <nav className='navbar bg-base-300'>
          <div className='flex-1'>
            {logoWithLink ? (
              <Link to={logoLink}>
                <StaticImage
                  height={36}
                  quality={100}
                  placeholder='none'
                  src='../images/logo.png'
                  alt='Fortula logo with hands around glass globe'
                />
              </Link>
            ) : (
              <StaticImage
                height={36}
                quality={100}
                placeholder='none'
                src='../images/logo.png'
                alt='Fortula logo with hands around glass globe'
              />
            )}
          </div>
          <div className='flex-none'>
            <ul className='menu menu-horizontal p-0'>
              {!hideAuthentication && user === null && (
                <li>
                  <div className='p-0'>
                    <button className='btn' onClick={() => setShowAuthentication(true)}>
                      {t('authentication.login')}
                    </button>
                  </div>
                </li>
              )}
              {!hideAuthentication && user?.current_order_id && (
                <li>
                  <Link to={`/dashboard/fortunes/${user?.current_order_id}`}>{t('menu.last_fortune')}</Link>
                </li>
              )}
            </ul>
          </div>
        </nav>
        <div className={`${className ?? 'lg:py-16 py-6'} ${lowContent ? 'flex-grow' : ''}`}>{children}</div>
        {!hideFooter && (
          <footer className='footer footer-center p-10 bg-neutral text-white'>
            <div>
              <p className='font-bold'>Fortula</p>
              <div className='cursor-pointer' onClick={() => navigateWithLanguage(resolvedLanguage, '/contacts')}>
                {t('contacts.title')}
              </div>
              <div className='cursor-pointer' onClick={() => navigateWithLanguage(resolvedLanguage, '/privacy-policy')}>
                {t('menu.privacy_policy')}
              </div>
              {showLanguageSwitcher && (
                <div>
                  <LanguageSwitcher />
                </div>
              )}
              <p>Copyright © 2022 - All right reserved</p>
            </div>
          </footer>
        )}
      </div>
    </>
  );
};

export default PageLayout;
