import React, {useRef, useState} from 'react';
import {QuizData} from '../../services/fortune';
import {useTranslation} from 'react-i18next';
import {IoCheckmarkSharp} from 'react-icons/io5';

interface Props {
  onGenderNameSubmit: (gender: 'male' | 'female', name: string) => void;
  quizData: QuizData;
}

const GenderNameStep = ({onGenderNameSubmit, quizData}: Props) => {
  const {t} = useTranslation();
  const [gender, setGender] = useState<QuizData['gender']>(quizData.gender);
  const [error, setError] = useState<'gender' | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    const name = e.target.name.value;

    if (gender === null) {
      setError('gender');

      return;
    }

    onGenderNameSubmit(gender, name);
  };

  const onGenderChange = (gender: QuizData['gender']) => {
    setError(null);
    setGender(gender);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <form className='flex gap-8 lg:flex-row flex-col flex-wrap justify-center items-center' onSubmit={onFormSubmit}>
      <div className='flex flex-col lg:flex-row gap-4 w-full lg:w-auto'>
        <div
          className={`flex gap-2 ${error === 'gender' ? 'tooltip tooltip-open tooltip-error' : ''}`}
          data-tip={t('quiz.gender_name.errors.gender')}
        >
          <button
            className='btn bg-[#2b6cac] hover:bg-[#2b6cac] text-white text-xs lg:w-auto w-[calc(50%-0.25rem)]'
            onClick={() => onGenderChange('male')}
            type='button'
          >
            <div className='flex'>
              {t('quiz.gender_name.male')}
              {gender === 'male' && <IoCheckmarkSharp className='ml-2' />}
            </div>
          </button>
          <button
            className='btn bg-[#991973] hover:bg-[#991973] text-white text-xs lg:w-auto w-[calc(50%-0.25rem)]'
            onClick={() => onGenderChange('female')}
            type='button'
          >
            <div className='flex'>
              {t('quiz.gender_name.female')}
              {gender === 'female' && <IoCheckmarkSharp className='ml-2' />}
            </div>
          </button>
        </div>
        <input
          required
          name='name'
          ref={inputRef}
          placeholder={t('quiz.gender_name.first_name')}
          defaultValue={quizData.name || ''}
          className='input text-neutral bg-white lg:w-auto w-full focus:outline-none focus:border-2 focus:border-secondary'
        />
      </div>
      <div>
        <button className='btn btn-secondary w-44 text-white' type='submit'>
          {t('quiz.continue')}
        </button>
      </div>
    </form>
  );
};

export default GenderNameStep;
