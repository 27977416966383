const LT_MAP: {[from: string]: string} = {
  as: 'ai',
  us: 'au',
  is: 'i',
  ys: 'i',
};

export const mapNameToGreeting = (name: string, language: string): string => {
  if (language === 'lt') {
    const suffixPattern = new RegExp(`(${Object.keys(LT_MAP).join('|')})$`);

    const suffixMatch = name.match(suffixPattern);

    if (suffixMatch) {
      const suffix = suffixMatch[1];
      const mappedSuffix = LT_MAP[suffix];
      return name.slice(0, name.length - suffix.length) + mappedSuffix;
    }
  }

  return name;
};
