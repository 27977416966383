type Steps = 'question' | 'card-pick' | 'gender-name' | 'birthdate' | 'country-email' | 'payment';

export interface QuizData {
  titles: {id: string; arguments?: object}[];
  question: string | null;
  order_id: string | null;
  step: Steps;
  initialCards: string[];
  selectedCards: string[];
  gender: 'male' | 'female' | null;
  name: string;
  birthdate: string | null;
  email: string | null;
  countryCode: string | null;
}

export type ZodiacSign =
  | 'aries'
  | 'taurus'
  | 'gemini'
  | 'cancer'
  | 'leo'
  | 'virgo'
  | 'libra'
  | 'scorpio'
  | 'sagittarius'
  | 'capricorn'
  | 'aquarius'
  | 'pisces';

const cards = [
  'ace-of-cups',
  'ace-of-pentacles',
  'ace-of-swords',
  'ace-of-wands',
  'blank',
  'death',
  'eight-of-cups',
  'eight-of-pentacles',
  'eight-of-swords',
  'eight-of-wands',
  'five-of-cups',
  'five-of-pentacles',
  'five-of-swords',
  'five-of-wands',
  'four-of-cups',
  'four-of-pentacles',
  'four-of-swords',
  'four-of-wands',
  'judgement',
  'justice',
  'king-of-cups',
  'king-of-pentacles',
  'king-of-swords',
  'king-of-wands',
  'knight-of-cups',
  'knight-of-pentacles',
  'knight-of-swords',
  'knight-of-wands',
  'nine-of-cups',
  'nine-of-pentacles',
  'nine-of-swords',
  'nine-of-wands',
  'page-of-cups',
  'page-of-pentacles',
  'page-of-swords',
  'page-of-wands',
  'queen-of-cups',
  'queen-of-pentacles',
  'queen-of-swords',
  'queen-of-wands',
  'seven-of-cups',
  'seven-of-pentacles',
  'seven-of-swords',
  'seven-of-wands',
  'six-of-cups',
  'six-of-pentacles',
  'six-of-swords',
  'six-of-wands',
  'strength',
  'temperance',
  'ten-of-cups',
  'ten-of-pentacles',
  'ten-of-swords',
  'ten-of-wands',
  'the-chariot',
  'the-devil',
  'the-emperor',
  'the-empress',
  'the-fool',
  'the-hanged-man',
  'the-heirophant',
  'the-hermit',
  'the-high-priestess',
  'the-lovers',
  'the-magician',
  'the-moon',
  'the-star',
  'the-sun',
  'the-tower',
  'the-world',
  'three-of-cups',
  'three-of-pentacles',
  'three-of-swords',
  'three-of-wands',
  'two-of-cups',
  'two-of-pentacles',
  'two-of-swords',
  'two-of-wands',
  'wheel-of-fortune',
];

export const pickInitialCards = (): string[] => {
  return [...Array(3)].map(() => {
    const fortuneNumber = Math.floor(Math.random() * cards.length);

    return cards.splice(fortuneNumber, 1)[0];
  });
};

export const dateToZodiac = (date: Date): ZodiacSign | undefined => {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  if ((month === 3 && day >= 21) || (month === 4 && day <= 20)) {
    return 'aries';
  } else if ((month === 4 && day >= 21) || (month === 5 && day <= 21)) {
    return 'taurus';
  } else if ((month === 5 && day >= 22) || (month === 6 && day <= 21)) {
    return 'gemini';
  } else if ((month === 6 && day >= 22) || (month === 7 && day <= 22)) {
    return 'cancer';
  } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
    return 'leo';
  } else if ((month === 8 && day >= 23) || (month === 9 && day <= 23)) {
    return 'virgo';
  } else if ((month === 9 && day >= 24) || (month === 10 && day <= 23)) {
    return 'libra';
  } else if ((month === 10 && day >= 24) || (month === 11 && day <= 21)) {
    return 'scorpio';
  } else if ((month === 11 && day >= 22) || (month === 12 && day <= 22)) {
    return 'sagittarius';
  } else if ((month === 12 && day >= 23) || (month === 1 && day <= 20)) {
    return 'capricorn';
  } else if ((month === 1 && day >= 21) || (month === 2 && day <= 19)) {
    return 'aquarius';
  } else if ((month === 2 && day >= 20) || (month === 3 && day <= 20)) {
    return 'pisces';
  }
};
