import React, {useMemo, useState} from 'react';
import {QuizData} from '../../services/fortune';
import {useTranslation} from 'react-i18next';
import {Metadata} from '../../services/api/metadataAPI';
import {useI18next} from 'gatsby-plugin-react-i18next';

interface Props {
  onCountryEmailSubmit: (countryCode: string, email: string) => void;
  quizData: QuizData;
  metadata: Metadata | null;
}

const countries = require('i18n-iso-countries');

const CountryEmailStep = ({onCountryEmailSubmit, quizData, metadata}: Props) => {
  const {t} = useTranslation();
  const {language} = useI18next();
  const [changeCountry, setChangeCountry] = useState<boolean>(!metadata?.country_code || quizData.countryCode !== null);

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    let country;

    if (metadata?.country_code && !changeCountry) {
      country = metadata.country_code;
    } else {
      country = e.target.country.value;
    }

    const email = e.target.email.value;

    if (country === '' || email === '') {
      //todo: set error on input
      return;
    }

    onCountryEmailSubmit(country, email);
  };

  const availableCountries = useMemo(() => Object.keys(countries.getAlpha3Codes()), []);

  return (
    <form className='flex flex-col items-center mx-auto max-w-sm gap-2' onSubmit={onFormSubmit}>
      <input
        name='email'
        type='email'
        required
        placeholder={t('quiz.country_email.email')}
        defaultValue={quizData.email || ''}
        className='input text-neutral bg-white w-full focus:outline-none focus:border-2 focus:border-secondary'
      />
      {metadata?.country_code && !changeCountry && (
        <div className='bg-neutral px-2'>
          {t('quiz.country_email.your_country')}{' '}
          <span className='font-bold'>{countries.getName(metadata.country_code, language)}</span>
          <span className='underline text-sm ml-1 cursor-pointer' onClick={() => setChangeCountry(true)}>
            ({t('quiz.country_email.change_country')})
          </span>
        </div>
      )}
      {changeCountry && (
        <select
          required
          name='country'
          className='select text-black bg-white select-bordered w-full'
          defaultValue={quizData.countryCode || metadata?.country_code || ''}
        >
          <option value='' disabled>
            {t('quiz.country_email.country')}
          </option>
          {availableCountries.map((country) => (
            <option key={country} value={country}>
              {countries.getName(country, language)}
            </option>
          ))}
        </select>
      )}
      <div className='mt-6'>
        <button className='btn btn-secondary w-44 text-white' type='submit'>
          {t('quiz.continue')}
        </button>
      </div>
    </form>
  );
};

export default CountryEmailStep;
