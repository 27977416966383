import React, {useMemo, useState} from 'react';
import {getDaysInMonth} from 'date-fns';
import {QuizData} from '../../services/fortune';
import {useTranslation} from 'react-i18next';

interface Props {
  onBirthdateSubmit: (birthday: string) => void;
  quizData: QuizData;
}

const BirthdateStep = ({onBirthdateSubmit, quizData}: Props) => {
  const [year, setYear] = useState<string | null>(quizData.birthdate ? quizData.birthdate.split('-')[0] : null);
  const [month, setMonth] = useState<string | null>(quizData.birthdate ? quizData.birthdate.split('-')[1] : null);
  const {t} = useTranslation();

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    const year = e.target.year.value;
    const month = e.target.month.value;
    const day = e.target.day.value;

    if (year === '' || month === '' || day === '') {
      //todo: set error on input
      return;
    }

    onBirthdateSubmit(`${year}-${month}-${day}`);
  };

  const currentYear = new Date().getFullYear();

  const minYear = currentYear - 75;
  const maxYear = currentYear - 18;

  const yearOptions = useMemo(() => {
    const options = [];

    for (let i = maxYear; i >= minYear; i--) {
      options.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }

    return options;
  }, []);

  const dayOptions = useMemo(() => {
    const options = [];

    if (year === null || month === null) {
      return [];
    }

    const maxDay = getDaysInMonth(new Date(parseInt(year), parseInt(month) - 1));

    for (let i = 1; i <= maxDay; i++) {
      options.push(
        <option value={i.toString().padStart(2, '0')} key={i}>
          {i}
        </option>
      );
    }

    return options;
  }, [year, month]);

  return (
    <form className='flex lg:flex-row flex-col gap-8 flex-wrap justify-center items-center' onSubmit={onFormSubmit}>
      <div className='flex flex-wrap gap-2 justify-center'>
        <select
          required
          name='year'
          className='select text-black bg-white select-bordered focus:outline-none focus:border-2 focus:border-secondary'
          defaultValue={quizData.birthdate ? quizData.birthdate.split('-')[0] : ''}
          onChange={({target}) => setYear(target.value)}
        >
          <option value='' disabled>
            {t('quiz.birthdate.year')}
          </option>
          {yearOptions}
        </select>
        <select
          required
          name='month'
          className='select text-black bg-white select-bordered focus:outline-none focus:border-2 focus:border-secondary'
          defaultValue={quizData.birthdate ? quizData.birthdate.split('-')[1] : ''}
          onChange={({target}) => setMonth(target.value)}
        >
          <option value='' disabled>
            {t('quiz.birthdate.month')}
          </option>
          {[
            t('quiz.birthdate.january'),
            t('quiz.birthdate.february'),
            t('quiz.birthdate.march'),
            t('quiz.birthdate.april'),
            t('quiz.birthdate.may'),
            t('quiz.birthdate.june'),
            t('quiz.birthdate.july'),
            t('quiz.birthdate.august'),
            t('quiz.birthdate.september'),
            t('quiz.birthdate.october'),
            t('quiz.birthdate.november'),
            t('quiz.birthdate.december'),
          ].map((value, id) => (
            <option key={value} value={(id + 1).toString().padStart(2, '0')}>
              {value}
            </option>
          ))}
        </select>
        <select
          required
          name='day'
          className='select text-black bg-white select-bordered focus:outline-none focus:border-2 focus:border-secondary'
          defaultValue={quizData.birthdate ? quizData.birthdate.split('-')[2] : ''}
        >
          <option value='' disabled>
            {t('quiz.birthdate.day')}
          </option>
          {dayOptions}
        </select>
      </div>
      <div>
        <button className='btn btn-secondary w-44 text-white' type='submit'>
          {t('quiz.continue')}
        </button>
      </div>
    </form>
  );
};

export default BirthdateStep;
