import React, {useEffect, useState} from 'react';
import CardSelectStep from '../components/fortuneQuiz/CardSelectStep';
import {dateToZodiac, pickInitialCards, QuizData} from '../services/fortune';
import SelectedCard from '../components/fortuneQuiz/SelectedCard';
import GenderNameStep from '../components/fortuneQuiz/GenderNameStep';
import BirthdateStep from '../components/fortuneQuiz/BirthdateStep';
import {parse} from 'date-fns';
import CountryEmailStep from '../components/fortuneQuiz/CountryEmailStep';
import {useTranslation} from 'react-i18next';
import {graphql} from 'gatsby';
import {createOrder} from '../services/api/orderAPI';
import {useAuthentication} from '../context/authentication';
import {navigateWithLanguage} from '../services/navigation';
import PageLayout from '../components/PageLayout';
import {fetchMetadata, Metadata} from '../services/api/metadataAPI';
import {getData, store} from '../services/storage';
import countries from 'i18n-iso-countries';
import {pushEvent} from '../services/gtmManager';
import {Helmet} from '../components/Helmet';
import '../styles/index.scss';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import {IoCheckmarkSharp} from 'react-icons/io5';
import {mapNameToGreeting} from '../services/nameMapper';
import QuestionStep from '../components/fortuneQuiz/QuestionStep';

//todo: think how to register dynamically
countries.registerLocale(require(`i18n-iso-countries/langs/lt.json`));
countries.registerLocale(require(`i18n-iso-countries/langs/ru.json`));
countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));

const TarotReadingPage = () => {
  const {
    t,
    i18n: {resolvedLanguage},
  } = useTranslation();

  const {registerUser, user} = useAuthentication();
  const [metadata, setMetadata] = useState<Metadata | null>(null);

  const persistedQuizData = getData<QuizData>('quiz');

  const [quizData, setQuizData] = useState<QuizData>(
    persistedQuizData ?? {
      titles: [{id: 'quiz.question.title'}],
      question: null,
      order_id: null,
      initialCards: [],
      selectedCards: [],
      step: 'question',
      gender: null,
      name: '',
      birthdate: null,
      email: null,
      countryCode: null,
    }
  );

  useEffect(() => {
    if (user !== null) {
      setQuizData({
        ...quizData,
        email: user.email,
        countryCode: user.country_code,
      });
    }
  }, [user]);

  useEffect(() => {
    if (quizData.initialCards.length === 0) {
      const fortuneCards = pickInitialCards();

      fortuneCards.forEach((slug) => {
        new Image().src = `/images/tarot/${slug}.png`;
      });

      setQuizData({
        ...quizData,
        initialCards: fortuneCards,
      });
    }
  }, []);

  useEffect(() => {
    store('quiz', quizData);

    window.scrollTo(0, 0);
  }, [quizData]);

  const onCardSelected = (cardSlug: string, order: 0 | 1 | 2) => {
    if (order === 0) {
      setQuizData({
        ...quizData,
        selectedCards: [...quizData.selectedCards, cardSlug],
        step: 'gender-name',
        titles: [
          {
            id: 'quiz.card_draw.selected_card_dots',
            arguments: {card: `tarot.${cardSlug}`},
          },
          {id: 'quiz.gender_name.title2'},
          {id: 'quiz.gender_name.title3'},
        ],
      });
    } else if (order === 1) {
      setQuizData({
        ...quizData,
        selectedCards: [...quizData.selectedCards, cardSlug],
        step: 'birthdate',
        titles: [
          {
            id: 'quiz.card_draw.selected_card_excl',
            arguments: {card: `tarot.${cardSlug}`},
          },
          {id: 'quiz.birthdate.title2'},
          {id: 'quiz.birthdate.title3'},
        ],
      });
    } else {
      setQuizData({
        ...quizData,
        selectedCards: [...quizData.selectedCards, cardSlug],
        step: 'country-email',
        titles: [
          {
            id: 'quiz.card_draw.selected_card_dots',
            arguments: {card: `tarot.${cardSlug}`},
          },
          {id: 'quiz.country_email.title2'},
          {id: 'quiz.country_email.title4'},
        ],
      });
    }
  };

  const onGenderNameSubmit = (gender: 'male' | 'female', name: string) => {
    (async function () {
      if (user === null) {
        const {data} = await fetchMetadata();
        setMetadata(data);
      }
    })();

    setQuizData({
      ...quizData,
      step: 'card-pick',
      gender,
      name,
      titles: [
        {
          id: 'quiz.gender_name.submit_title1',
          arguments: {name: mapNameToGreeting(name, resolvedLanguage)},
        },
        {id: 'quiz.gender_name.submit_title2'},
      ],
    });

    pushEvent('quizGenderSubmit');
  };

  const onBirthdateSubmit = (birthdate: string) => {
    const zodiacSign = dateToZodiac(parse(birthdate, 'yyyy-MM-dd', new Date()));

    setQuizData({
      ...quizData,
      step: 'card-pick',
      birthdate,
      titles: [
        {
          id: `zodiac.${zodiacSign}_title`,
          arguments: {zodiacSign: `zodiac.${zodiacSign}`, dindzia: `zodiac.${zodiacSign}`},
        },
        {
          id: 'quiz.birthdate.submit_title2',
        },
      ],
    });

    pushEvent('quizBirthdateSubmit');
  };

  const onQuestionSubmit = (question: string) => {
    setQuizData({
      ...quizData,
      question,
      step: 'card-pick',
      titles: [
        {
          id: 'quiz.card_draw.draw_first_card',
        },
      ],
    });

    pushEvent('quizStart');
    pushEvent('web', {action: 'quizQuestion', label: question});
  };

  const onCountryEmailSubmit = async (countryCode: string, email: string) => {
    //todo: refactor or remove
    const updatedQuizData: QuizData = {
      ...quizData,
      step: 'payment',
      email,
      countryCode,
      titles: [{id: 'quiz.payment.title1'}],
    };

    if (user === null) {
      await registerUser({
        type: 'email',
        email: updatedQuizData.email!,
        name: updatedQuizData.name,
        country_code: updatedQuizData.countryCode!,
        gender: updatedQuizData.gender as 'male' | 'female',
        birthdate: updatedQuizData.birthdate!,
        language: resolvedLanguage,
      });
    }

    const {data: orderData} = await createOrder(updatedQuizData, resolvedLanguage);

    updatedQuizData.order_id = orderData.id;

    setQuizData(updatedQuizData);

    pushEvent('quizEmailSubmit');

    await navigateWithLanguage(resolvedLanguage, `/dashboard/fortunes/${orderData.id}`, {state: orderData});
  };

  const renderTitle = (title: QuizData['titles'][0]) => {
    if (title.arguments !== undefined) {
      const currentArguments: any = title.arguments;
      const mappedArguments: {[argument: string]: string} = {};

      Object.keys(title.arguments).forEach((argument: string) => {
        mappedArguments[argument] = t(currentArguments[argument] as string);
      });

      return t(title.id, mappedArguments);
    }

    return t(title.id, title.arguments);
  };

  return (
    <>
      <Helmet title={t('quiz.meta_title')} description={t('quiz.meta_description')} />
      <PageLayout
        logoWithLink={quizData.selectedCards.length === 0}
        showLanguageSwitcher={false}
        hideAuthentication
        hideFooter
        background='bg-stars'
      >
        <>
          {quizData.step === 'question' && (
            <header className='mb-6'>
              <div className='text-center relative text-primary bg-base-100 bg-opacity-60'>
                <h1 className='lg:text-5xl text-4xl font-bold mb-2 px-2'>{t('quiz.title')}</h1>
                <p className='text-2xl px-2'>{t('quiz.subtitle')}</p>
              </div>
            </header>
          )}
          <main className='relative'>
            <section className='max-w-4xl container pb-6 z-10 relative'>
              <div className='max-w-lg mx-auto bg-base-100 bg-opacity-70 rounded-2xl mb-8'>
                {quizData.titles.map((title, id) => (
                  <p
                    key={id}
                    className={`text-2xl text-center ${
                      quizData.titles.length === id + 1 ? 'text-secondary mt-3 font-bold' : 'text-white'
                    }`}
                  >
                    {renderTitle(title)}
                  </p>
                ))}
              </div>
              <div className='lg:mb-10 mb-2'>
                {quizData.step === 'question' && (
                  <QuestionStep onQuestionSubmit={onQuestionSubmit} quizData={quizData} />
                )}
                {quizData.step === 'card-pick' && (
                  <CardSelectStep
                    onCardSelected={onCardSelected}
                    cardSlug={quizData.initialCards[quizData.selectedCards.length]}
                    order={quizData.selectedCards.length as 0 | 1 | 2}
                  />
                )}
                {quizData.step === 'gender-name' && (
                  <GenderNameStep onGenderNameSubmit={onGenderNameSubmit} quizData={quizData} />
                )}
                {quizData.step === 'birthdate' && (
                  <BirthdateStep onBirthdateSubmit={onBirthdateSubmit} quizData={quizData} />
                )}
                {quizData.step === 'country-email' && (
                  <CountryEmailStep
                    metadata={metadata}
                    onCountryEmailSubmit={onCountryEmailSubmit}
                    quizData={quizData}
                  />
                )}
              </div>
              {quizData.selectedCards.length > 0 && (
                <section className='flex justify-center gap-4 mt-6'>
                  {quizData.selectedCards[0] && (
                    <SelectedCard
                      small={quizData.step === 'payment'}
                      cardSlug={quizData.selectedCards[0]}
                      pulse={quizData.selectedCards.length === 0}
                    />
                  )}
                  {quizData.selectedCards[1] && (
                    <SelectedCard
                      small={quizData.step === 'payment'}
                      cardSlug={quizData.selectedCards[1]}
                      pulse={quizData.selectedCards.length === 0}
                    />
                  )}
                  {quizData.selectedCards[2] && (
                    <SelectedCard
                      small={quizData.step === 'payment'}
                      cardSlug={quizData.selectedCards[2]}
                      pulse={quizData.selectedCards.length === 0}
                    />
                  )}
                </section>
              )}
            </section>
            {quizData.step === 'question' && (
              <section className='text-white text-center'>
                <div className='inline-flex flex-col items-center bg-black bg-opacity-80 py-12 lg:px-12 px-4 rounded-2xl'>
                  <h3 className='text-3xl font-bold mb-6 lg:text-left text-center'>
                    {t('landing.fortune_offer.header')}
                  </h3>
                  <div>
                    <div className='flex gap-2 items-center mb-2'>
                      <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                      <div>{t('landing.fortune_offer.text1')}</div>
                    </div>
                    <div className='flex gap-2 items-center mb-2'>
                      <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                      <div>{t('landing.fortune_offer.text2')}</div>
                    </div>
                    <div className='flex gap-2 items-center mb-2'>
                      <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                      <div>{t('landing.fortune_offer.text3')}</div>
                    </div>
                    <div className='flex gap-2 items-center mb-2'>
                      <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                      <div>{t('landing.fortune_offer.text4')}</div>
                    </div>
                    <div className='flex gap-2 items-center mb-2'>
                      <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                      <div>{t('landing.fortune_offer.text5')}</div>
                    </div>
                    {/*<div className='flex gap-2 items-center'>*/}
                    {/*  <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />*/}
                    {/*  <div>{t('landing.fortune_offer.text6')}</div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </section>
            )}
          </main>
        </>
      </PageLayout>
    </>
  );
};

export default TarotReadingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["quiz", "tarot", "zodiac", "index"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
