import React from 'react';
import {Helmet as ReactHelmet, HelmetProps} from 'react-helmet';
import {useI18next} from 'gatsby-plugin-react-i18next';

interface Props extends HelmetProps {
  title: string;
  description?: string;
}

export const Helmet: React.FC<Props> = ({children, ...props}) => {
  const {languages, language, originalPath, defaultLanguage, siteUrl = ''} = useI18next();

  const createUrlWithLang = (lng: string) => {
    const url = `${siteUrl}${lng === defaultLanguage ? '' : `/${lng}`}${originalPath}`;
    return url.endsWith('/') ? url.slice(0, -1) : url;
  };

  return (
    <ReactHelmet {...props}>
      <html lang={language} />
      <link rel='canonical' href={createUrlWithLang(language)} />
      <meta property='og:title' content={props.title} />
      {props.description && <meta name='description' content={props.description} />}
      {props.description && <meta property='og:description' content={props.description} />}
      <meta property='og:url' content={createUrlWithLang(language)} />
      {languages.length > 1 &&
        languages.map((lng) => <link rel='alternate' key={lng} href={createUrlWithLang(lng)} hrefLang={lng} />)}
      {languages.length > 1 && <link rel='alternate' href={createUrlWithLang(defaultLanguage)} hrefLang='x-default' />}
      {children}
    </ReactHelmet>
  );
};
