import {QuizData, ZodiacSign} from '../fortune';
import api, {ApiPromise} from '../api';

export type TarotResult = {
  [key in 'past' | 'present' | 'future']: {
    name: string;
    description: string;
    slug: string;
  };
};

export type ZodiacResult = {
  slug: ZodiacSign;
  name: string;
  dates: string;
  description: string;
  personality_description: string;
  decan: {
    index: number;
    dates: string;
    description: string;
  };
  additional_description: string;
  gender: 'male' | 'female';
  gender_description: string;
  folk_wisdom: string;
};

export type NameResult = {
  name: string;
  description: string | null;
  gender: string;
  origin: string;
  name_day: string | null;
};

export type BirthdateResult = {
  birthdate: string;
  number: number;
  type: string | null;
  character: string;
  career: string;
  love: string;
  health: string;
  suggestion: string;
  talisman: string;
  special_description: string;
};

export type PythagoreanSquareResult = {
  [num in 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9]: {
    count: number;
    description: string;
    similar_people: string | null;
    category: string;
  };
};

type Details = {
  tarot: TarotResult | null;
  zodiac: ZodiacResult | null;
  name: NameResult | null;
  birthdate: BirthdateResult | null;
  pythagorean_square: PythagoreanSquareResult | null;
};

export interface Order {
  id: string;
  status: 'created' | 'processing' | 'ready';
  language: string;
  name: string;
  price_amount: string;
  price_currency: string;
  order_data: {
    cards: string[];
  };
  details: Details;
  default_price_amount: string | null;
  payment_methods: {
    title: string;
    key: string;
    logo_url: string | null;
  }[] | null;
}

export interface FortuneCheckoutResponse {
  order: Order;
  redirect_url: string;
}

export const createOrder = (data: QuizData, language: string): ApiPromise<Order> =>
  api.post(
    '/orders',
    {
      email: data.email,
      name: data.name,
      order_data: {
        question: data.question,
        cards: data.selectedCards,
        gender: data.gender,
        birthdate: data.birthdate,
        country_code: data.countryCode,
      },
    },
    {headers: {'X-Language': language}}
  );

export const fetchOrder = (id: string, language: string, special?: boolean): ApiPromise<Order> =>
  api.get(`/orders/${id}`, {headers: {'X-Language': language}, params: {special: special ? 1 : undefined}});

export const checkoutOrder = (
  id: string,
  language: string,
  paymentMethod: string
): ApiPromise<FortuneCheckoutResponse> =>
  api.put(`/orders/${id}/checkout`, {payment_method: paymentMethod}, {headers: {'X-Language': language}});

export const confirmOrderPayment = (id: string, language: string, payment_data: any): ApiPromise<Order> =>
  api.put(`/orders/${id}/confirm-payment`, {payment_data}, {headers: {'X-Language': language}});
