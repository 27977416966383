import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectCards, Navigation} from 'swiper';

interface Props {
  order: 0 | 1 | 2;
  cardSlug: string;
  onCardSelected: (cardSlug: string, order: Props['order']) => void;
}

const CardSelectStep = ({cardSlug, order, onCardSelected}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCardClick = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    onCardSelected(cardSlug, order);

    setIsLoading(false);
  };

  return (
    <Swiper threshold={5} slidesPerView={1} effect={'cards'} loop navigation modules={[EffectCards, Navigation]}>
      {[...Array(22 - order)].map((e, i) => (
        <SwiperSlide className='cursor-pointer' onClick={onCardClick} key={i} />
      ))}
    </Swiper>
  );
};

export default CardSelectStep;
